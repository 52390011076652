import { call, put, select, takeLatest } from "redux-saga/effects";
import * as ACTIONS from "./TableActions";
import * as TYPES from "./TableTypes";
import { getTableBookingApi, getTableOrder } from "./TableApi";
import moment from "moment";

export function* sagasTableRequest({ payload }) {
  try {
    const hotelKey = payload;

    // // Check if the API call is already in progress
    // const isTableRequestPending = yield select(
    //   (state) => state.table.isTableRequestPending
    // );

    // if (isTableRequestPending) {
    //   // The API call is already in progress, do not make another call
    //   return;
    // }

    // Set the loading state to true
    yield put(ACTIONS.setTableRequestPending(true));

    if (!hotelKey) {
      throw new Error("Something went wrong");
    }

    const response = yield call(getTableOrder, hotelKey);

    if (!response.status) {
      throw new Error("Something went wrong");
    }

    // Dispatch your success action
    yield put(ACTIONS.getTableReceive(response.data));
    yield put(ACTIONS.setTableRequestPending(false));
  } catch (err) {
    console.log("error", err);
  }
}

export function* sagaTableBooking(action) {
  try {
    // Make the API call
    const { payload } = action;

    // Perform any necessary data transformations, such as formatting the date
    const formattedDate = moment(payload.date, "dddd, MMMM D, YYYY").format(
      "DD/MM/YYYY"
    );

    // Construct the modified payload
    const modifiedPayload = {
      ...payload,
      date: formattedDate,
    };
    const response = yield call(getTableBookingApi, modifiedPayload);

    // Dispatch a success action with the response data
    yield put(ACTIONS.submitFormSuccess(response?.data));
  } catch (error) {
    // Dispatch a failure action with the error
    yield put(ACTIONS.submitFormFailure(error));
  }
}

export function* TableSaga() {
  yield takeLatest(TYPES.GET_TABLE_ORDER_REQUEST, sagasTableRequest);
}

export function* TableBookingSaga() {
  yield takeLatest(TYPES.TABLE_BOOKING_REQUEST, sagaTableBooking);
}
