import axios from "axios";
import { axiosInstance } from "../../network/apis";
import { API } from "./../../utils/Constants";

export const getTableOrder = async (hotelKey) => {
  const response = await axiosInstance.post(
    `${API.ENDPOINTS.GET_TABLE_BOOKING}`,
    { id: `${hotelKey}` },
    { handlerEnabled: false }
  );
  return response;
};

export const getTableBookingApi = async (payload) => {
  const response = await axiosInstance.post(
    `${API.ENDPOINTS.GET_TABLE_BOOKING_MAIL}`,
    payload,
    { handlerEnabled: false }
  );
  return response;
};
