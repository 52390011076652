export const APP_NAME = process.env.REACT_APP_NAME;

export const API = {
  BASE_URL: process.env.REACT_APP_BASE_URL,
  VERSION: "",
  ENDPOINTS: {
    GET_TABLE_BOOKING: "getTableBooking",
    GET_TABLE_BOOKING_MAIL: "getTableBookingmail",
  },
};

export const APP_VERSION = process.env.REACT_APP_VERSION;
export const BUGSNAG_KEY = "5fdbd5fff3f07e8cb8442fa39a0cfbac";
export const BOOKINGS_STATUS = {
  ACCEPTED: "ACCEPTED",
  PENDING: "PENDING",
  REJECTED: "REJECTED",
};
