import { combineReducers } from "redux";

import table from "../Table/TableReducer";
import loader from "../Loader/LoaderReducers";
import theme from "../Theme/ThemeReducer";

export default combineReducers({
  table,
  loader,
  theme,
});
